import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IdiomaService } from '../../servicios/idioma.service';

@Component({
  selector: 'mse-contenedor',
  templateUrl: './contenedor.component.html',
  styleUrls: ['./contenedor.component.scss']
})
export class ContenedorComponent {
  literalesCargados: BehaviorSubject<boolean> = this.idioma.init();
  constructor(private idioma: IdiomaService) { }
}
