import { Component, OnInit } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { HttpService, HttpData } from 'src/app/shared/servicios/http.service';
import { IdiomaService } from '../../servicios/idioma.service';

interface Mensaje {
  nombre: string;
  telefono: string;
  email: string;
  mensaje: string;
}

@Component({
  selector: 'mse-contactenos',
  templateUrl: './contactenos.component.html',
  styleUrls: ['./contactenos.component.scss']
})
export class ContactenosComponent implements OnInit {
  private url: string = 'https://script.google.com/macros/s/AKfycbys0KplhXTLlKnBha_capma6hja2KDskTtEPM8EsBgE4AWnQ9r8tPQXBHXtCmSrXUoC/exec';
  mensaje: HttpData = { nombre: '', telefono: '', email: '', mensaje: '' };
  contacto = new FormGroup({
    nombre: new FormControl('', [Validators.required, Validators.minLength(5)]),
    telefono: new FormControl('', [Validators.pattern('[0-9\+ ]*'), Validators.minLength(10)]),
    email: new FormControl('', [Validators.required, Validators.email]),
    mensaje: new FormControl('', [Validators.required]),
  });
  literales: any;
  constructor(
    private http: HttpService,
    private idioma: IdiomaService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private snackBar: MatSnackBar
  ) { }
  ngOnInit(): void {
    this.idioma.literales.subscribe({ next: (lit: any) => this.literales = lit ? lit.contactenos : null });
  }
  enviarMensaje() {
    this.recaptchaV3Service.execute('contactenos')
      .subscribe({
        next: (token: string) => {
          this.mensaje['nombre'] = this.contacto.get('nombre')?.value ?? '';
          this.mensaje['email'] = this.contacto.get('email')?.value ?? '';
          this.mensaje['telefono'] = this.contacto.get('telefono')?.value ?? '';
          this.mensaje['mensaje'] = this.contacto.get('mensaje')?.value ?? '';
          this.http.put(this.url, this.mensaje)
            .subscribe({
              next: (resp: any) => {
                if (resp.error === null) {
                  this.generaSnackBar(true);
                } else {
                  this.generaSnackBar(false);
                }
              },
              error: (err: any) => {
                console.log('Error de CORS, pero no es incapacitante', err);
                this.generaSnackBar(true);
              }
            });
        },
        error: (error: any) => {
          this.generaSnackBar(false);
        },
      });
  }
  generaSnackBar(correcto: boolean): void {
    const mensaje: string = correcto ?
      'Mensaje enviado correctamente' :
      'Ha ocurrido un error: intente enviar el mensaje de nuevo';
    const sbRef: MatSnackBarRef<any> = this.snackBar.open(mensaje, 'Cerrar', { duration: 3000 });
    sbRef.afterOpened().subscribe({
      next: () => {
        this.mensaje = { nombre: '', telefono: '', email: '', mensaje: '' };
        this.contacto.reset();
        this.contacto.markAsUntouched();
      }
    });
  }
  err(control: string, error: string): Boolean {
    let salida: boolean = false;
    switch (control) {
      case 'nombre':
        if (this.contacto.controls.nombre.errors && this.contacto.controls.nombre.errors[error]) salida = true;
        break;
      case 'telefono':
        if (this.contacto.controls.telefono.errors && this.contacto.controls.telefono.errors[error]) salida = true;
        break;
      case 'email':
        if (this.contacto.controls.email.errors && this.contacto.controls.email.errors[error]) salida = true;
        break;
      case 'mensaje':
        if (this.contacto.controls.mensaje.errors && this.contacto.controls.mensaje.errors[error]) salida = true;
        break;
      default:
        salida = false;
    }
    return salida;
  }
}
