<div mse-fondo
     class="inicio">
  <a mat-fab
     matTooltip="Contáctenos"
     routerLink="contactenos"
     color="primary"
     aria-label="Contáctenos">
    <mat-icon>mail</mat-icon>
  </a>
  <mat-card class="logo">
    <img src="/assets/fondo/icono.svg"
         alt="Logo de Mediateca Soluciones Educativas">
  </mat-card>
</div>