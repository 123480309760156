<div mse-fondo
     class="contactenos">
  <div class="formulario">
    <mat-card *ngIf="literales">
      <mat-card-title>{{literales.titulo}}</mat-card-title>
      <form (ngSubmit)="enviarMensaje()"
            [formGroup]="contacto">
        <mat-card-content>
          <mat-form-field appearance="outline">
            <mat-label>{{literales.nombre}}</mat-label>
            <input matInput
                   formControlName="nombre"
                   type="text"
                   name="nombre"
                   required>
            <mat-error *ngIf="err('nombre', 'required')">
              {{literales.error.required}}
            </mat-error>
            <mat-error *ngIf="err('nombre', 'minlength')">
              {{literales.error.minlength}}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{literales.telefono}}</mat-label>
            <input matInput
                   formControlName="telefono"
                   type="tel"
                   name="telefono">
            <mat-error *ngIf="err('telefono', 'pattern')">
              {{literales.error.pattern}}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{literales.email}}</mat-label>
            <input matInput
                   formControlName="email"
                   type="email"
                   name="email"
                   required>
            <mat-error *ngIf="err('email', 'required')">
              {{literales.error.required}}
            </mat-error>
            <mat-error *ngIf="err('email', 'email')">
              {{literales.error.email}}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{literales.mensaje}}</mat-label>
            <textarea matInput
                      formControlName="mensaje"
                      name="mensaje"
                      rows="6"
                      required>
            </textarea>
            <mat-error *ngIf="err('mensaje', 'required')">
              {{literales.error.required}}
            </mat-error>
          </mat-form-field>
        </mat-card-content>
        <mat-card-actions>
          <button mat-raised-button
                  color="primary"
                  type="submit"
                  [disabled]="contacto.invalid">
            {{literales.enviar}}
          </button>
        </mat-card-actions>
      </form>
      <div class="fondo">
        <img src="/assets/fondo/icono.svg">
      </div>
    </mat-card>
  </div>
</div>