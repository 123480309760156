import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface HttpData {
  [key: string]: string;
}

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(private http: HttpClient) { }
  put(url: string, data: HttpData): Observable<any> {
    const postData: FormData = new FormData();
    Object.keys(data).forEach((key: string) => postData.append(key, data[key]));
    return this.http.post<any>(url, postData, { responseType: 'json' });
  }
  get(url: string, data: HttpData | undefined = undefined): Observable<any> {
    if (data) {
      url += '?';
      Object.keys(data).forEach((key: string) => url + encodeURI(key) + '=' + encodeURI(data[key]) + '&');
      url = url.slice(0, -1);
    }
    return this.http.get<any>(url, { responseType: 'json' });
  }
}
