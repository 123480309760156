import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/servicios/http.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdiomaService {
  public literales: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private lang: any;
  constructor(private http: HttpService) { }
  init(idioma: string = 'es'): BehaviorSubject<boolean> {
    const carga: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    this.http.get('assets/data/lang.json').subscribe({
      next: (resp: any) => {
        carga.next(true);
        this.lang = resp;
        this.cambia(idioma);
      }
    });
    return carga;
  }
  cambia(idioma: string): void {
    this.literales.next(this.lang[idioma]);
  }
}
